//import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/swiper-bundle.css'
import './../css/font-awesome.css'
// import '@fortawesome/fontawesome-free/css/fontawesome.css';
// import '@fortawesome/fontawesome-free/js/fontawesome.js';
// import '@fortawesome/fontawesome-free/js/all'
// import '@fortawesome/fontawesome-free/css/all.css'
import './../css/custom.css'
//import 'bootstrap/dist/js/bootstrap.min.js'
import Swiper from 'swiper'


// var $ = require('jquery')

//The following code was taken from https://css-tricks.com/snippets/javascript/support-tabs-in-textareas/

// HTMLTextAreaElement.prototype.getCaretPosition = function () { //return the caret position of the textarea
//     return this.selectionStart;
// };
// HTMLTextAreaElement.prototype.setCaretPosition = function (position) { //change the caret position of the textarea
//     this.selectionStart = position;
//     this.selectionEnd = position;
//     this.focus();
// };
// HTMLTextAreaElement.prototype.hasSelection = function () { //if the textarea has selection then return true
//     if (this.selectionStart == this.selectionEnd) {
//         return false;
//     } else {
//         return true;
//     }
// };
// HTMLTextAreaElement.prototype.getSelectedText = function () { //return the selection text
//     return this.value.substring(this.selectionStart, this.selectionEnd);
// };
// HTMLTextAreaElement.prototype.setSelection = function (start, end) { //change the selection area of the textarea
//     this.selectionStart = start;
//     this.selectionEnd = end;
//     this.focus();
// };

// var textarea = document.getElementsByTagName('textarea')[0];

// if (textarea) {
//     textarea.onkeydown = function (event) {

//         var key = event.key || event.keyCode; //replace references to event.keyCode with key in below code
//         //support tab on textarea
//         if (key == 9) { //tab was pressed
//             var newCaretPosition;
//             newCaretPosition = textarea.getCaretPosition() + "    ".length;
//             textarea.value = textarea.value.substring(0, textarea.getCaretPosition()) + "    " + textarea.value.substring(textarea.getCaretPosition(), textarea.value.length);
//             textarea.setCaretPosition(newCaretPosition);
//             return false;
//         }
//         if (key == 8) { //backspace
//             if (textarea.value.substring(textarea.getCaretPosition() - 4, textarea.getCaretPosition()) == "    ") { //it's a tab space
//                 var newCaretPosition;
//                 newCaretPosition = textarea.getCaretPosition() - 3;
//                 textarea.value = textarea.value.substring(0, textarea.getCaretPosition() - 3) + textarea.value.substring(textarea.getCaretPosition(), textarea.value.length);
//                 textarea.setCaretPosition(newCaretPosition);
//             }
//         }
//         if (key == 37) { //left arrow
//             var newCaretPosition;
//             if (textarea.value.substring(textarea.getCaretPosition() - 4, textarea.getCaretPosition()) == "    ") { //it's a tab space
//                 newCaretPosition = textarea.getCaretPosition() - 3;
//                 textarea.setCaretPosition(newCaretPosition);
//             }
//         }
//         if (key == 39) { //right arrow
//             var newCaretPosition;
//             if (textarea.value.substring(textarea.getCaretPosition() + 4, textarea.getCaretPosition()) == "    ") { //it's a tab space
//                 newCaretPosition = textarea.getCaretPosition() + 3;
//                 textarea.setCaretPosition(newCaretPosition);
//             }
//         }
//     }
// }

$(function () {

    $(".navbar-toggler").on('click', function () {
        $(this).toggleClass("manu_open");
        $('body').toggleClass("manu_open_body");
    });

    $(".filter-tabs").on('click', function () {
        $(this).toggleClass("sidebar_open");
        $('body').toggleClass("sidebar_open_body");
        $('.mobile-box').toggleClass("open_sidebar");
    });

    var swiper = new Swiper('.swiper-container', {
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    // ACTIVE CLASS IN CURRENT PAGE
    // var url = window.location.href;
    // // now grab every link from the navigation
    // $('.main-navigation a').each(function () {
    //     // and test its normalized href against the url pathname regexp
    //     if (this.href === url) {
    //         $(this).addClass('active');
    //     } else {
    //         $(this).removeClass('active');
    //     }
    // });

    // MOBILE MENU - RESPONSIVE
    // $('.nav-toggle').click(function () {
    //     var collapse_content_selector = $(this).attr('id');
    //     var toggle_switch = $(this);
    //     $(collapse_content_selector).toggle(function () {
    //         if ($(this).css('display') == 'none') {
    //             toggle_switch.attr("src", toggle_switch.attr("src").replace("images/menu-close.png", "images/menu-icon.png"));
    //         } else {
    //             toggle_switch.attr("src", toggle_switch.attr("src").replace("images/menu-icon.png", "images/menu-close.png"));
    //         }
    //     });
    // });

    // $('.nav-toggle').on('click', function (e) {
    //     $('.main-navigation').toggleClass("active-sidebar");
    //     $('html').toggleClass("active-overflow");

    //     if ($(".main-navigation").hasClass("active-sidebar")) {
    //         $(this).attr('src', "/static/pages/images/menu-close.png");

    //     } else {
    //         $(this).attr('src', "/static/pages/images/menu-icon.png");
    //     }
    //     e.preventDefault();
    // });

    //Initialize Swiper 
    // var mySwiper = new Swiper('#swiper-container', {
    //     loop: true,
    //     pagination: {
    //         el: '#swiper-pagination',
    //         dynamicBullets: true,
    //     },
    //     navigation: {
    //         nextEl: '#swiper-button-next',
    //         prevEl: '#swiper-button-prev',
    //     },
    // })

    // var featuresSwiper = new Swiper('#features-swiper-container', {
    //     loop: true,
    //     pagination: {
    //         el: '#features-swiper-pagination',
    //         dynamicBullets: true,
    //     },
    //     navigation: {
    //         nextEl: '#features-swiper-button-next',
    //         prevEl: '#features-swiper-button-prev',
    //     },
    // })

    // $('.tabs .tab-links a').on('click', function (e) {
    //     var currentAttrValue = $(this).attr('href');
    //     // Show/Hide Tabs
    //     $('.tabs ' + currentAttrValue).show().siblings().hide();
    //     // Change/remove current tab to active
    //     $(this).parent('li').addClass('active').siblings().removeClass('active');
    //     e.preventDefault();
    // });

    $('.download').on('click', function (e) {
        var url = $(this).attr('data-url');
        e.preventDefault();
        window.location.href = url;
    });

    // $('.filter-tabs').click(function () {
    //     $('.mobile-box').toggleClass('activedoc');
    // });

    // $('.nav-link').click(function () {
    //     $('.mobile-box').removeClass('activedoc');
    // });

    // $('.user-ic').click(function () {
    //     $('.dashboard-mobile-filter').toggleClass('activedashboard');
    // });

    // $('.close-dashboard').click(function () {
    //     $('.dashboard-mobile-filter').removeClass('activedashboard');
    // });
});

export function showErrorMessage(message) {
    var messages = $('#messages');
    messages.append(`
        <div class="alert alert-danger alert-dismissible" role="alert" >
            <button type="button" class="close" data-dismiss="alert" aria-label = "Close" >
                <span aria-hidden="true" > &times; </span> 
            </button> 
            <p>${message}</p>
        </div>`);
    // scroll to messages so that the message would be seen
    document.querySelector('#messages').scrollIntoView({
        behavior: 'smooth'
    })
}


export function clearErrorMessages() {
    var messages = $('#messages');
    messages.empty();
}

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function loading(status) {
    if (status) {
        $('#loading').removeClass('d-none');
    } else {
        $('#loading').addClass('d-none');
    }
}

$('#examples_language').on('change', function () {
    var lang_tag = $("#examples_language option:selected").attr("data-tag");
    var page_num = $("#page_num").text()
    var url = `/examples/${lang_tag}/?page=${page_num}`;
    window.location.href = url;
})

$('#docs_language').on('change', function () {
    const lang_tag = $('#docs_language option:selected').attr('data-tag');
    const category = $('#docs_language option:selected').attr('data-category');
    const url = `/docs/${lang_tag}/${category}`;
    window.location.href = url;
})

export async function downloadImage() {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'image file name here'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}